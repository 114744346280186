export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'Price Plans',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'Users',
    },
    {
        to: {name: 'admin.companies.index'},
        icon: 'mdi-diamond',
        text: 'Companies',
    },
    {
        to: {name: 'admin.assessments.index'},
        icon: 'mdi-document',
        text: 'Assessments',
    },
    {
        to: {name: 'admin.chats.index'},
        icon: 'mdi-message',
        text: 'chats',
    },
    {
        to: {name: 'admin.consultations.index'},
        icon: 'mdi-account-multiple',
        text: 'Consultations',
    },
]
